import React from 'react';
import {fromLonLat} from 'ol/proj';
import {Geometry, Point} from 'ol/geom';
import {Geolocation as OLGeoLoc} from 'ol';
import 'ol/ol.css';

import { RLayerVector, RFeature, RGeolocation, RStyle, useOL} from 'rlayers';
import locationIcon from './location.svg';

export default function GeolocComp()  {
    const [pos, setPos] = React.useState(new Point(fromLonLat([0, 0])));
    const [accuracy, setAccuracy] = React.useState(null);
    // Low-level access to the OpenLayers API
    const {map} = useOL();

    return (
        <>
            <RGeolocation
                tracking={true}
                trackingOptions={{enableHighAccuracy: true}}
                onChange={React.useCallback(
                    (e) => {
                        const geoloc = e.target;
                        setPos(new Point(geoloc.getPosition()));
                        setAccuracy(geoloc.getAccuracyGeometry());

                        // map.getView().fit(geoloc.getAccuracyGeometry(), {
                        //     duration: 250,
                        //     maxZoom: 15
                        // });
                    },
                    []
                )}
            />
            <RLayerVector zIndex={10}>
                <RStyle.RStyle>
                    <RStyle.RIcon src={locationIcon} anchor={[0.5, 0.8]} />
                    <RStyle.RStroke color={'#007bff'} width={3} />
                </RStyle.RStyle>
                <RFeature geometry={pos}></RFeature>
                <RFeature geometry={accuracy}></RFeature>
            </RLayerVector>
        </>
    );
}

