import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Layout from './layout';
import { Link } from "react-router-dom";

export default function CompleteLoop() {    
    return (
        <Layout>
            <Box sx={{ m: 2}}>
                <Typography variant="h3">Completing the Loop</Typography>
                <Typography  >Congratulations on completing the Walkabout Path! If you have done it all in one day, you are exceptional!</Typography>
                <Typography >To get back to your car, the easiest thing would be to call an <Link to="https://uber.com">Uber</Link>!</Typography>
                <Typography>You've probably already figured this out, but here's your situation:</Typography>
                <Box sx={{ m: 2}}>
                <img src="img/backtostart/route_map1.png" alt="Back to Beginning" />
                </Box>
            <Box sx={{   m: 2}}>
                <Typography>On two feet, you have limited options today to get back to the start. 
                     You can of course, turn around and go back around the way you came.
                    Or you can walk back to Main Street across the Depot Street Bridge.  But getting to the trailhead at Andrasko Road still means driving on Route 8 from Exit 24 to Exit 25. 
                    We would like to build a trailhead from somewhere in downtown Beacon Falls connecting to the trail you began on near Egypt Brook.  
                    Or even maybe one day a footbridge across the river connecting the Naugatuck State Forest's East and West Blocks as part of Naugatuck Valley 
                    Greenway project. 
                    Stay tuned for more updates on this project or visit our <Link to="/planning">Planning</Link> page.
                </Typography>
                </Box>
                 <Typography variant="h6">We hope you have enjoyed the Walkabout Path and have enjoyed the beauty of the natural areas of Beacon Falls.</Typography>
            </Box>
        </Layout>
    );
}