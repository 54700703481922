import React from 'react';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Table from '@mui/material/Table';
import { TableHead, TableBody, TableRow, TableCell } from '@mui/material';
 
import Accordion from '@mui/material/Accordion';
 
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
 



import { Typography
 } from '@mui/material';
import Layout from './layout';
export default function Planning() {
    return (
        <div>
            <Layout >
            < Box sx={{ m: "2", border: "1"}}> 
            <Typography variant="h3">Planning the walkabout</Typography>
            <img src="/img/home/route_overview_1.png" alt="route overview"></img>
            <Typography >The ideas discussed here about how to connect existing trails into a complete walkabout are entirely my own, but they complement nicely with the greenway plans from the Naugatuck Council of Governments Greenway plan from 2010.  </Typography>
         <List sx={{border: 1, m: 1}}><Typography>Useful Links</Typography>
            <ListItem>
                <Typography>Home page for Naugatuck Valley Council of Governments: &nbsp;</Typography>
            <Link to="https://nvcogct.gov">Naugatuck Valley Council of Governments</Link>
            </ListItem>
            <ListItem>
            <Typography>Naugatuck River Greenway Routing Study Executive Summary:  &nbsp; </Typography> 
            <Link to="https://nvcogct.gov/wp-content/uploads/2014/03/COGCNV-Naugatuck-River-Greenway-Routing-Study-Executive-Summary.pdf">Greenway Executive Summary</Link>
            </ListItem>
            <ListItem>
            <Typography>Detailed plans for Greenway include designing a path through NSF: &nbsp;</Typography>
            <Link to="https://nvcogct.gov/wp-content/uploads/2014/03/COGCNV-Naugatuck-River-Greenway-Routing-Study-Beacon-Falls_0.pdf">NV Council of Govenments Routing Study for Beacon Falls (2010)</Link>
            </ListItem>
            <ListItem>
            <Link to="https://nvcogct.gov/wp-content/uploads/2014/03/COGCNV-Naugatuck-River-Greenway-Routing-Study-Overview.pdf">Greenway Routing Study Overview</Link>
            </ListItem>
        </List>
            <Typography  variant="h4">Planning for trail improvements </Typography>
<Box sx={{m: 2}}>           
<Typography> 
In support of the vision laid out above for a walking path circling the town and giving residents of the town access to this path from the center, 
we envision several projects that could be accomplished over a period of years.  
These projects will create access trails and connections of existing trails as well as signage and communication to make people aware of the trail and support their enjoyment of it.
</Typography>
</Box>
{/* 
<List>
<ListItem>Planning, review and refinement of circuit trail route (Walkabout)</ListItem>
<ListItem>Assessment of all needed trail improvements to mark circuit route</ListItem>
<ListItem>Creation of Access trails from center of town to points on the route</ListItem>
<ListItem>Signage and Pedestrian Safety improvements for road sections</ListItem>
<ListItem>Plan for access to circuit route from train station.</ListItem>
<ListItem>Engineering Survey for Pedestrian footbridge over river to complete circuit</ListItem>
<ListItem>Marketing, Communication, and online information</ListItem>
</List> */}
<Divider />
<Accordion>
<AccordionSummary   
    expandIcon={<ExpandMoreIcon />}
    aria-controls="panel1-content"
    id="panel1-header"
>   
Planning of Beacon Falls Walkabout
</AccordionSummary>
<AccordionDetails>
<Typography>
Review of proposed route will require communication with landowners and town officials regarding access and permission for both pedestrian access and signage where necessary.  In addition to town officials with Parks and Rec and Public Works, consultation with DEEP personnel responsible for Naugatuck State Forest about the proposed route and signage.  Consultation with Bethany Land Trust for the section of the route that goes through their trails.  If an access point near the elementary school is considered, then obviously school officials will also need to be consulted.  For parts of the route planned along public roads, consultation with Public Works officials on pedestrian safety measures and route signage will be valuable.  For example, a cross walk across Rt. 42 may be necessary along parts of the route.
Planning will involve little direct expenditure except perhaps for printed materials or other supporting items to capture and convey the proposed route.  
</Typography>
<List>
<ListItem>Estimated Time:  6 months</ListItem>
<ListItem>Estimated Expenses: $500</ListItem>
</List>
</AccordionDetails>
</Accordion>

<Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
         Signage for Beacon Falls Walkabout
        </AccordionSummary>
        <AccordionDetails>
            <Typography>With consultation and endorsement from officials mentioned above, appropriate sign markers will need to be designed, made, and placed in appropriate places for hikers to use them on their way.  We expect that the design and creation of markers will require paid services while the installation of the markers can be done by volunteers or appropriate agency employees.  Signage that includes a QR code may help hikers access additional information en route.
            </Typography>
            <List>
                <ListItem>Estimated Time: 6 months</ListItem>   
                <ListItem>Estimated Expenses: $1,500</ListItem>
            </List>
        </AccordionDetails>
</Accordion>
<Accordion>
<AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel2-header"
        >
        Access Trails Construction
        </AccordionSummary>
        <AccordionDetails>
            <Box sx={{m: 2}}>
            <Typography>
            Currently identified new trails needed to allow access to circuit route:</Typography>
            <List sx={{ listStyle: "decimal" }}>
                <ListItem  sx={{ display: "list-item" }}>Pent Rd to Naugatuck State Forest</ListItem>
                <ListItem sx={{ display: "list-item" }}>Lopus Rd Ext to Pent Rd Park</ListItem>
                <ListItem sx={{ display: "list-item" }}>Lorraine Dr to NSF</ListItem>
                <ListItem sx={{ display: "list-item" }}>Laurel Ledge to NSF</ListItem>
            </List>
            </Box>

        </AccordionDetails>
</Accordion>


<Accordion>
<AccordionSummary
            expandIcon={<ExpandMoreIcon />} 
            aria-controls="panel1-content"  
            id="panel3-header"  
        >

Access Trail: 1. Pent Rd to Naugatuck State Forest
</AccordionSummary>
<AccordionDetails>
            <Typography>
            Plans for 2 spur trails from Pent Road Town Park to an existing Naugatuck State Forest Trail.  These spur trails would go across land owned by the town (39 acre) and connect to the same trail forming a loop for people to enjoy.  Pent Road field has adequate parking and is popular with residents.  Giving people access and awareness of the trailhead with appropriate signage will increase access to the forest for all residents.
            (2 spurs connecting to existing NSF trail to form a loop trail)
            One spur is marked on this map, though it is poorly marked and in need of some improvements to the path in both clearing and signage.  The other spur to the north is very steep (see topo in appendix) and will require some logs or other wood stair installation for general usage.

                         </Typography>
            <ImageList>
                <ImageListItem key="pentrd_map1" >
                    <img src="/img/planning/pentrd_map1.png" alt="Spur Trails" />
                <ImageListItemBar position="below" title="New Spur Trails" /></ImageListItem>
            </ImageList>             
            <List>
                <ListItem>Estimated Time: 6 months</ListItem>   
                <ListItem>Estimated Expenses: $2,000</ListItem>
            </List>
        </AccordionDetails>
        </Accordion>
        <Accordion>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel4-header"
        >
        Access Trail: 2. Lopus Rd Ext to Pent Rd Park
        </AccordionSummary>
        <AccordionDetails>
            <Typography>
            In support of connecting Pent Rd Park to High Rock and Metro-North Railroad, a new trail constructed from an existing access point would help hikers make a loop from Pent Rd to High Rock / Spruce Brook Falls and back.
            The proposed route would be over private land, so the permission and cooperation of the landowners will be necessary.
            The terrain is flat and lightly wooded, so trail construction should be straightforward.
            In conjunction with the spur trail connecting the Town Recreation Field to the NSF, this trail would allow hikers a quicker return to their starting point from a loop that included either High Rock or Spruce Brook Falls.  The alternative is that the hiker has a longer walk along Lopus Road Ext and Pent Road to return to their car.
            </Typography>
            <ImageList>
            <ImageListItem key="pentrd_map2" >
                    <img src="/img/planning/pentrd_map2.png" alt="Spur Trails" />
                <ImageListItemBar position="above" title="New Spur Trails" /></ImageListItem>
             </ImageList>
            <List>
                <ListItem>Estimated Time: 6 months (after landowner permission secured)</ListItem>
                <ListItem>Estimated Expense: $500</ListItem>
            </List>
        </AccordionDetails>
        </Accordion>
        <Accordion> 
        <AccordionSummary   
            expandIcon={<ExpandMoreIcon />} 
            aria-controls="panel1-content"  
            id="panel5-header"
                    >
        Access Trail: 3. Lorraine Dr to NSF
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        Planning and Construction of a Trailhead from Lorraine Dr to the Naugatuck State Forest.  In conjunction with a recent land sale, the town has required the buyer to construct an appropriate trailhead and parking to give people access to the Naugatuck State Forest East Block from the end of Lorraine Dr.  Exact path of the trail is not yet determined nor where the parking / trailhead will be placed.
                        </Typography>
                        <ImageList>
                            <ImageListItem key="lorraine_map" >
                                <img src="/img/planning/lorraine_map.png" alt="Lorraine Spur Trail" />
                                <ImageListItemBar position="below" title="Lorraine Road Spur Trail" />
                                </ImageListItem>
                            
                        </ImageList>
                        <List>
                            <ListItem>Estimated Time: 1 year</ListItem>

                            <ListItem>Estimated Expense: $5,000</ListItem>

                        </List>
                    </AccordionDetails> 
                    </Accordion>
                    <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />} 
                        aria-controls="panel1-content"
                        id="panel6-header"
                    >   
                    Access Trail: 4. Laurel Ledge to NSF
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        Construction of a trailhead from around Laurel Ledge Elementary School would give the most access to the most residents.  The area around the elementary school is densely populated and the Naugatuck State Forest is tantalizingly close.  But, today, no access point exists for neighborhood residents who want to enjoy the woods.  It is utterly insane that people need to drive an exit up the highway to enjoy woods they can see from their front door! 
                        Respecting the interests of property owners, the most straightforward route would be from the West side of the Laurel Ledge Recreation Fields (with School permission of course).  It appears from town property records that the West Side of the Laurel Ledge fields is the Naugatuck State Forest:
                        While on the North side is a small strip that is privately owned.  It is possible that the owners could agree to an easement to allow a more direct trail.
                        This trail would also allow the most direct course for completing the walkabout circuit as mentioned earlier (short of the footbridge).
                        The <Link to="https://nvcogct.gov/wp-content/uploads/2014/03/COGCNV-Naugatuck-River-Greenway-Routing-Study-Beacon-Falls_0.pdf">Greenway Routing Study</Link>  (pg. 52) also suggested building a trail from the school to the existing trails as a short term solution to the challenge of building the Greenway trail north of Main Street.
                        </Typography>
                        <ImageList>
                            <ImageListItem key="laurel_ledge_spur1" >
                                <img src="/img/planning/laurel_ledge_spur1.png" alt="Laurel Ledge Spur Trail" />
                                <ImageListItemBar position="below" title="Laurel Ledge Spur Trail" />
                            </ImageListItem>
                            <ImageListItem key="laurel_ledge_spur2" >
                                <img src="/img/planning/routing_laurel_ledge.png" alt="Greenway Routing" />
                                <ImageListItemBar position="below" title="Proposal from Greenway Trail Study" />
                            </ImageListItem>
                            <ImageListItem key="laurel_ledge_spur4" >
                                <img src="/img/planning/laurel_ledge_spur4.png" alt="Lorraine Spur Trail" />
                                <ImageListItemBar position="below" title="Laurel Ledge Spur Trail" />
                            </ImageListItem>     
                        </ImageList>
                        <List>  
                            <ListItem>Estimated Time: 1 year</ListItem>
                            <ListItem>Estimated Expense: $3,000</ListItem>
                        </List>
                    </AccordionDetails>

                    </Accordion>
                    <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel7-header"
                    >   
                    Road Sections of Walkabout
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        In coordination with the Department of Public Works, agree on a plan for signage and safety improvements to make the sections of the trail along public roads safe for hikers and other pedestrians.  As mentioned in the overview sections, 3 sections of the route would be along roads:
                        Mesa Drive to Oakwood Drive (Road)

                        Kaleas Way to Lantern Ridge Trailhead on Skokorat Rd (Road)

                        Starwood Lane to Main Street to Matthies Park (Road / cross river)

                        The first part, Mesa Drive to Oakwood Drive is on cul-de-sac roads.  The only concern would be crossing Blackberry Hill Road safely.
                        The second part, some concern must be given to pedestrian safety on Skokorat Road.  The road has little room for pedestrians and the terrain of the road means that drivers can not always see walkers quickly enough.
                        The final and longest section will need the most discussion and review.  At 2 miles it is a section along a major road (Rt. 42) where pedestrian safety may require some improvements or other enhancements.
                        This section overlaps with the Southeast option route for the Naugatuck River Greenway. Depending on the decisions about the safety concerns for pedestrian traffic, this section could require significant expense and time to complete.  
                        </Typography>
                        <List>
                            <ListItem>Estimated Time: 2 years</ListItem>
                            <ListItem>Estimated Expense: $5,000</ListItem>

                        </List>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel8-header"
                    >
                    Plan for access to walkabout from train station
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        As mentioned in the history overview, Beacon Falls once attracted nature lovers to come to the town by train.  Review of the access to the walkabout from the Beacon Falls train station would give the town an opportunity to invite people from all over to visit our beautiful town and enjoy some very special natural features.  Spur trails reviewed earlier could be part of a plan for encouraging people to visit Beacon Falls by Rail (Leave your car behind in Beacon Falls!)
                        It is possible to imagine creating trail routes from Beacon Falls train station that head in 4 directions using basically the same routes as the walkabout.
                        </Typography>
       
                        <ImageList>
                        <ImageListItem key="rails_to_trails" >
                                <img src="/img/planning/rails_to_trails.png" alt="Rails to Trails" />
                                <ImageListItemBar position="below" title="Rails to Trails" />
                            </ImageListItem>     
                        </ImageList>
                        <List>
                            <ListItem>Train to High Rock</ListItem>
                            <ListItem>Train to Matthies Park</ListItem> 
                            <ListItem>Train to Egypt Brook</ListItem>
                            <ListItem>Train to Rock Rimmon</ListItem>
                        </List>
                        <List>
                            <ListItem>Estimated Time: 1 year</ListItem>
                            <ListItem>Estimated Expense: $500</ListItem>
                        </List>
                    </AccordionDetails>
                    </Accordion>


                    <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel9-header"
                    >Pedestrian Footbridge</AccordionSummary>
                    <AccordionDetails>
                            <Typography>
The most ambitious idea for the Beacon Falls walkabout would be the construction of a footbridge across the Naugatuck River connecting the East and West blocks of the State Forest.  
First steps would be to discuss with DEEP officials responsible for the Naugatuck State Forest.  And to engage an independent engineering firm to assess the feasibility and effort to create a bridge as well as the type and suitable location.  Initial proposal would be to engage a civil engineering firm in a feasibility study and general plan for cost and time to complete.
This was proposed as part of the Naugatuck River Greenway plan in 2010. 
<Link to="https://nvcogct.gov/wp-content/uploads/2014/03/COGCNV-Naugatuck-River-Greenway-Routing-Study-Beacon-Falls_0.pdf">Routing Study</Link> pg 26, 30, 77
 The bridge would be a significant asset to the town and the region.  It would be a significant draw for hikers and nature lovers from all over the region.  It would also be a significant asset to the town in terms of property values and quality of life.

</Typography>
<ImageList variant="masonry" cols={1} gap={8}>
    <ImageListItem key="footbridge1" >
        <img src="/img/planning/footbridge_map1.png" alt="Footbridge Map" />
        <ImageListItemBar position="below" title="Greenway Map with Footbridge Option" />
    </ImageListItem> 
    <ImageListItem key="footbridge2" >
        <img src="/img/planning/footbridge_illus.png" alt="Footbridge Conceptual Illustration" />
        <ImageListItemBar position="below" title="Possible Illustration of Footbridge" />
    </ImageListItem>
    <ImageListItem key="footbridge3" >
        <img src="/img/planning/footbridge_cost.png" alt="Footbridge Cost" />
        <ImageListItemBar position="below" title="Preliminary Cost Estimate (2010)" />
    </ImageListItem>     
</ImageList>
<List>
    <ListItem>Estimated Time: 3 months</ListItem>
    <ListItem>Estimated Expense: $10,000 (for feasibility study only)</ListItem>
</List>
</AccordionDetails>
</Accordion>
<Accordion>
<AccordionSummary   
    expandIcon={<ExpandMoreIcon />}
    aria-controls="panel1-content"
    id="panel10-header"
>Marketing, Communication, and Online Information
</AccordionSummary>
<AccordionDetails>
    <Typography>
Building a circuit around Beacon Falls will not deliver value unless people know it exists.  Using Social Media and volunteer or Town web resources, build a website with information on the Beacon Falls walkabout, resources for hikers and other information pertinent to the general public.
</Typography>
<List>
    <ListItem>Estimated Time: 1 year</ListItem>
    <ListItem>Estimated Expense: $5,000</ListItem>
</List>
</AccordionDetails>
</Accordion>

<Divider />
<Box>
    <Typography >&nbsp;</Typography>
<Typography  sx={{ m: "2"}} variant="h2">Proposal Summary</Typography>
 


<Table>
    <TableHead>
        <TableRow>
<TableCell> Project Name</TableCell>
<TableCell>Priority </TableCell>
<TableCell>Total Time </TableCell>
<TableCell>Total Expense </TableCell>
<TableCell>Start </TableCell>
</TableRow>
</TableHead>
<TableBody>
<TableRow><TableCell> Planning of Beacon Falls Walkabout</TableCell> 
<TableCell>1</TableCell> 
<TableCell> 6 months</TableCell>  
<TableCell> $500</TableCell> 
<TableCell> 4/1/24</TableCell> 
</TableRow>
<TableRow>
    <TableCell>Signage for Beacon Falls Walkabout</TableCell> 
<TableCell>2</TableCell>
<TableCell>6 months</TableCell>
<TableCell>$1500</TableCell>
<TableCell>10/1/24</TableCell>
</TableRow>
<TableRow>
    <TableCell>Access Trail: Pent Rd to Naugatuck State Forest</TableCell>
    <TableCell>3</TableCell>
    <TableCell>6 months</TableCell>
    <TableCell>$2000</TableCell>
    <TableCell>4/1/24</TableCell>
</TableRow>
<TableRow>
    <TableCell>Access Trail: Lopus Rd Ext to Pent Rd Park</TableCell>
    <TableCell>4</TableCell>
    <TableCell>6 months</TableCell>
    <TableCell>$500</TableCell>
    <TableCell>TBD</TableCell>
</TableRow>
<TableRow>
    <TableCell>Access Trail: Lorraine Dr to NSF</TableCell>
    <TableCell>5</TableCell>
    <TableCell>1 year</TableCell>
    <TableCell>$5000</TableCell>
    <TableCell>TBD</TableCell>
</TableRow>
<TableRow>
    <TableCell>Access Trail: Laurel Ledge to NSF</TableCell>    
    <TableCell>6</TableCell>    
    <TableCell>1 year</TableCell>
    <TableCell>$3000</TableCell>
    <TableCell>TBD</TableCell>
</TableRow>
<TableRow>
    <TableCell>
        Road Section Improvements
    </TableCell>
    <TableCell>
        7
    </TableCell>
    <TableCell>
        2 years
    </TableCell>
    <TableCell>
        $5000
    </TableCell>
    <TableCell>
        TBD
    </TableCell>
    </TableRow>
    <TableRow>
        <TableCell>
            Train Station connections
        </TableCell>
        <TableCell>
            8   
        </TableCell>
        <TableCell>
            1 year
        </TableCell>    
        <TableCell>
            $500    
        </TableCell>
        <TableCell>
            TBD 
        </TableCell>
    </TableRow>
    <TableRow>
        <TableCell>
            Pedestrian Footbridge
        </TableCell>
        <TableCell>
            9
        </TableCell>
        <TableCell>
            18 months
        </TableCell>
        <TableCell>
            $10,000
        </TableCell>
        <TableCell>
            TBD
        </TableCell>
</TableRow>
<TableRow>
    <TableCell>
        Marketing, Communication, Online Info
    </TableCell>
    <TableCell>
        10
    </TableCell>
    <TableCell>
        1 year
    </TableCell>
    <TableCell>
        $5000
    </TableCell>
    <TableCell>
        TBD
    </TableCell>
</TableRow>
<TableRow>
    <TableCell>
        GRAND TOTAL
    </TableCell>
    <TableCell>
        --
    </TableCell>
    <TableCell>
        3+ years
    </TableCell>
    <TableCell>
        $33,000
    </TableCell>
    <TableCell>
        TBD
    </TableCell>
</TableRow>
</TableBody>
</Table>
<Divider />
<Box sx={{ m: 2 }}>
<Typography >For more information, please contact us at: admin at bfwalkabout.org </Typography>
</Box>
</Box>

            </Box>
            </Layout>
        </div>
    );
}