import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Layout from './layout';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

import { Typography } from '@mui/material';

const itemData = [
    {"img": "img/nsfeb/nsfeb2.png", "type": "img", "section": "nsfeb", "title": "Egypt Brook", "cols": 2, "rows": 2},
    {"img": "img/nsfeb/nsfeb3.png", "type": "img", "section": "nsfeb", "title": "Waterfall by Route 8", "cols": 2, "rows": 2},
    {"img": "img/nsfeb/nsfeb_fog.png", "type": "img", "section": "nsfeb", "title": "Fog over Beacon Falls", "cols": 2, "rows": 2},
    {"img": "img/nsfeb/nsfeb_fog2.png", "type": "img", "section": "nsfeb", "title": "Fog over Beacon Falls", "cols": 2, "rows": 2},
    {"img": "img/nsfeb/nsfeb_hill.png", "type": "img", "section": "nsfeb", "title": "Rocky Hill top", "cols": 2, "rows": 2},
    {"img": "img/bethany/mendels1.png", "type": "img", "section": "bethany", "title": "Hill near Mendels Folly", "cols": 2, "rows": 2},
    {"img": "img/bethany/mendels2.png", "type": "img", "section": "bethany", "title": "Hockanum Brook Marshy Pond", "cols": 2, "rows": 2},
    {"img": "img/bethany/mendels3.png", "type": "img", "section": "bethany", "title": "Hockanum Brook Marshy Pond", "cols": 2, "rows": 2},
    {"img": "img/bethany/vanepps1.png", "type": "img", "section": "bethany", "title": "Sign for Van Epps Trail", "cols": 2, "rows": 2},
    {"img": "img/bethany/vanepps2.png", "type": "img", "section": "bethany", "title": "Large boulder on Van Epps Trail", "cols": 2, "rows": 2},
    {"img": "img/lr/lr1.png", "type": "img", "section": "bethany", "title": "Top of Rock Rimmon", "cols": 2, "rows": 2},
    {"img": "img/lr/lr2.png", "type": "img", "section": "bethany", "title": "Top of Rock Rimmon", "cols": 2, "rows": 2},
    {"img": "img/lr/lr3.png", "type": "img", "section": "bethany", "title": "Lantern Ridge Trail", "cols": 2, "rows": 2},
    {"img": "img/lr/lr4.png", "type": "img", "section": "bethany", "title": "Top of Rock Rimmon", "cols": 2, "rows": 2},
   
    {"img": "img/road/tobys.png", "type": "img", "section": "road", "title": "Toby's Pond", "cols": 2, "rows": 2},

    {"img": "img/matthies/matthies2.png", "type": "img", "section": "matthies", "title": "Matthies Pond", "cols": 2, "rows": 2},
    {"img": "img/matthies/matthies3.png", "type": "img", "section": "matthies", "title": "Matthies Pond", "cols": 2, "rows": 2},

    {"img": "img/nsfwb/nsfwb3.png", "type": "img", "section": "nsfeb", "title": "View of Beacon Falls", "cols": 2, "rows": 2},
    {"img": "img/nsfwb/nsfwb4.png", "type": "img", "section": "nsfeb", "title": "Spruce Brook Falls", "cols": 2, "rows": 2},
    {"img": "img/nsfwb/nsfwb5.png", "type": "img", "section": "nsfeb", "title": "Spruce Brook Falls", "cols": 2, "rows": 2},
    {"img": "img/nsfwb/nsfwb6.png", "type": "img", "section": "nsfeb", "title": "Spruce Brook Falls", "cols": 2, "rows": 2},
    {"img": "img/nsfwb/nsfwb7.png", "type": "img", "section": "nsfeb", "title": "Spruce Brook Falls", "cols": 2, "rows": 2},
    {"img": "img/nsfwb/nsfwb9.png", "type": "img", "section": "nsfeb", "title": "High Rock", "cols": 2, "rows": 2},
        
]

const mapData = [
    // {"img": "img//home/route_overview_1.png", "type": "map", "section": "all", "title": "Route Overview", "cols": 2, "rows": 2},
    {"img": "img/nsfeb/nsfeb4.png", "type": "map", "section": "nsfeb", "title": "Naugatuck SF East Block", "cols": 2, "rows": 2},
    {"img": "img/bethany/bethany2.png", "type": "map", "section": "bethany", "title": "Mendels Folly", "cols": 2, "rows": 2},
    {"img": "img/road/mesa_to_oakwood.png", "type": "map", "section": "bethany", "title": "Mesa Drive to Oakwood Drive", "cols": 2, "rows": 2},
    {"img": "img/lr/oakwood_to_kaleas.png", "type": "map", "section": "lr", "title": "Oakwood Drive to Kaleas Way", "cols": 2, "rows": 2},
    {"img": "img/road/kaleas_to_lantern_ridge1.png", "type": "map", "section": "lr", "title":  "Kaleas Way to Lantern Ridge", "cols": 2, "rows": 2},
    {"img": "img/lr/lantern_ridge.png", "type": "map", "section": "lr", "title": "Lantern Ridge", "cols": 2, "rows": 2},
    {"img": "img/road/starwood_to_matthies.png", "type": "map", "section": "road", "title": "Naugatuck River Greenway along Main Street", "cols": 2, "rows": 2},
    {"img": "img/matthies/matthies1.png", "type": "map", "section": "matthies", "title": "Matthies Park", "cols": 2, "rows": 2},
    {"img": "img/nsfwb/nsfwb1.png", "type": "map", "section": "nsfeb", "title": "Naugatuck SF West Block", "cols": 2, "rows": 2},
    {"img": "img/nsfwb/nsfwb2.png", "type": "map", "section": "nsfeb", "title": "Naugatuck SF West Block", "cols": 2, "rows": 2},
    {"img": "img/nsfwb/nsfwb8.png", "type": "img", "section": "nsfeb", "title": "Spruce Brook Falls to High Rock", "cols": 2, "rows": 2},
    

]
export default function Gallery({children}) {
    return (
        <Layout>
       <Box sx={{m: 2}}>
        <Typography variant="h2">Gallery</Typography>
    <ImageList variant="masonry" cols={3} gap={8}>
  {itemData.map((item) => (
    <ImageListItem key={item.img}>
      <img
        srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
        src={`${item.img}?w=248&fit=crop&auto=format`}
        alt={item.title}
        loading="lazy"
      />
            <ImageListItemBar title={item.title} position="below"/>
    </ImageListItem>
  ))}
</ImageList>
<Divider />
<Box>
    <img src="img/home/route_overview_2.png" alt="Beacon Falls" />
</Box>
<ImageList variant="masonry" cols={1} gap={8}>
  {mapData.map((item) => (
    <ImageListItem key={item.img}>
      <img
        srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
        src={`${item.img}?w=248&fit=crop&auto=format`}
        alt={item.title}
        loading="lazy"
      />
      <ImageListItemBar title={item.title} position="top"/>
    </ImageListItem>
  ))}
</ImageList>

        </Box>
        </Layout>
    );
}