'use client'
import React, {useCallback} from "react";
import { fromLonLat } from "ol/proj";
import LineString from 'ol/geom/LineString.js';
import VectorSource from 'ol/source/Vector';
import {RMap, ROSM,  RLayerVector, RStyle, RFeature, RInteraction, RControl } from 'rlayers';
import GeolocComp   from './GeolocComp';
import { Typography } from "@mui/material";
import {
  altShiftKeysOnly,

  shiftKeyOnly
} from 'ol/events/condition';
import "./walkabout-map.css";
import "ol/ol.css";

const nsfeb = [

  [-8131948.915415307, 5081629.338127303],
  [-8131971.671129061, 5081620.154743152],
  [-8132016.459486533, 5081580.834009487],
  [-8132102.318222007, 5081437.055777311],
  [-8132131.16171896, 5081364.968542529],
  [-8132169.563735283, 5081216.584633429],
  [-8132176.668481498, 5081137.840610543],
  [-8132172.353386126, 5080863.35997997],
  [-8132197.422923992, 5080737.006869601],
  [-8132248.65360223, 5080582.558197668],
  [-8132246.666325876, 5080551.838146115],
  [-8132152.8771448545, 5080574.171949906],
  [-8132148.146867334, 5080598.104688097],
  [-8132177.85804833, 5080651.629366689],
  [-8132169.148553131, 5080677.067806951],
  [-8132149.807595931, 5080674.392039183],
  [-8132138.047211681, 5080646.59948268],
  [-8132028.215205813, 5080602.589162997],
  [-8131987.280111884, 5080623.892749582],
  [-8131934.934506301, 5080640.739830965],
  [-8131891.359040501, 5080627.668658801],
  [-8131833.704701369, 5080644.455139172],
  [-8131745.354873, 5080595.354334467],
  [-8131716.889238452, 5080623.412603101],
  [-8131670.3841727665, 5080601.512329627],
  [-8131631.982156444, 5080643.364320953],
  [-8131630.647975605, 5080703.760222003],
  [-8131582.776074305, 5080763.51282],
  [-8131561.690419084, 5080818.2681653015],
  [-8131560.486857348, 5080871.294050949],
  [-8131505.384255696, 5080920.749138494],
  [-8131400.487785943, 5080930.18891154],
  [-8131259.465804233, 5080931.452209564],
  [-8131222.733846444, 5080942.840538234],
  [-8131151.397156259, 5080933.834295504],
  [-8131099.5926869605, 5080910.987713916],
  [-8131059.497287265, 5080848.410176429],
  [-8131000.793330341, 5080799.467866679],
  [-8130969.062218214, 5080777.656163915],
  [-8130881.706028023, 5080778.453300306],
  [-8130844.610202732, 5080769.55427475],
  [-8130822.456269846, 5080776.001290121],
  [-8130787.842207515, 5080754.208233822],
  [-8130755.332045737, 5080763.839133141],
  [-8130734.3117000675, 5080791.300714885],
  [-8130696.390175446, 5080767.288729218],
  [-8130695.653110507, 5080670.448311774],
  [-8130646.656951926, 5080537.671494115],
  [-8130619.665447256, 5080420.874697203],
  [-8130533.144286331, 5080355.7705636695],
  [-8130450.812266645, 5080257.662186588],
  [-8130396.488714643, 5080248.264368086],
  [-8130368.181689006, 5080240.013307198],
  [-8130344.595610954, 5080278.350439819],
  [-8130324.802151861, 5080284.35460164],
  [-8130324.993415547, 5080308.338617609],
  [-8130299.480705723, 5080332.951951782],
  [-8130251.898032437, 5080338.643785309],
  [-8130157.091888399, 5080273.451081066],
  [-8130052.951143455, 5080154.994748743],
  [-8130038.951574579, 5080108.192120885],
  [-8129942.144159282, 5080052.947305869],
  [-8129916.122967949, 5080022.231915933],
  [-8129923.969444074, 5079996.304005958],
  [-8129915.880389619, 5079969.718808084],
  [-8129785.135332345, 5079994.122369522],
  [-8129707.416965978, 5079963.043373513],
  [-8129642.8817990925, 5079953.384504496],
  [-8129580.184629586, 5079919.294104344],
  [-8129500.992133343, 5079924.962629789],
  [-8129449.043050038, 5079914.97744763],
  [-8129392.657582194, 5079840.596697619],
  [-8129356.681349215, 5079823.9034495745],
  [-8129311.687733152, 5079841.058197634],
  [-8129281.234822249, 5079830.690762938],
  [-8129212.743762525, 5079916.963296182],
  [-8129168.83241903, 5079923.498882264],
  [-8129139.391806177, 5080024.861067539],
  [-8129110.604288839, 5080034.976774955],
  [-8129012.327408627, 5079981.708485256],
  [-8128993.387638672, 5079951.589782208],
  [-8128986.394851686, 5079920.478154885],
  [-8128943.206578228, 5079875.847840231],
  [-8128943.206578228, 5079875.847840231]
];
const bethany = [
  [-8128944.623248889, 5079872.414191209],
[-8128953.870170253, 5079865.856155931],
[-8128968.198182139, 5079850.89832472],
[-8128973.099952246, 5079839.610548631],
[-8128973.510419779, 5079821.0033795135],
[-8128961.005671507, 5079803.992472024],
[-8128959.922493295, 5079795.690047013],
[-8128963.771144682, 5079788.053680551],
[-8128962.006963519, 5079776.531799904],
[-8128952.174400346, 5079773.328918041],
[-8128946.554312056, 5079773.078239705],
[-8128936.957042141, 5079765.846894852],
[-8128919.769232471, 5079737.6942676585],
[-8128916.251235484, 5079702.693564145],
[-8128921.853702693, 5079691.846028934],
[-8128936.0034307, 5079680.109725081],
[-8128935.160728417, 5079655.883010651],
[-8128947.413598888, 5079640.809163022],
[-8128936.7310776925, 5079623.622159181],
[-8128934.385400857, 5079612.830560458],
[-8128940.727953467, 5079606.742806023],
[-8128953.889864406, 5079599.8015022185],
[-8128968.2458627205, 5079560.543410454],
[-8128961.628628555, 5079553.760593364],
[-8128951.883134252, 5079551.460982194],
[-8128944.967378245, 5079543.071544869],
[-8128943.297521691, 5079532.222973797],
[-8128945.741669273, 5079523.3477177145],
[-8128952.570356411, 5079523.853217832],
[-8128964.743413748, 5079517.750961345],
[-8128971.650877483, 5079510.590055031],
[-8128978.146837151, 5079499.781882534],
[-8128978.999904775, 5079486.603660326],
[-8128986.002729653, 5079484.235682338],
[-8128997.2864406705, 5079470.154189437],
[-8129000.932967894, 5079447.422222266],
[-8128998.099083463, 5079435.266394755],
[-8128991.585502716, 5079418.281383789],
[-8128980.251001525, 5079404.946746588],
[-8128967.874783489, 5079397.234762282],
[-8128969.481394193, 5079391.699466931],
[-8128968.990640819, 5079383.781871359],
[-8128959.272845484, 5079357.773091007],
[-8128953.301954304, 5079343.679783214],
[-8128945.314783028, 5079328.993986034],
[-8128972.85385815, 5079297.709598549],
[-8128992.881817844, 5079275.84707546],
[-8129000.4938213285, 5079261.736963914],
[-8129004.89422184, 5079247.541153226],
[-8129006.74215465, 5079234.724090488],
[-8129007.851082481, 5079212.8548459085],
[-8128999.07550347, 5079185.552947927],
[-8128992.971776352, 5079172.434257817],
[-8128994.904623272, 5079132.3026942825],
[-8128998.95107487, 5079119.146195729],
[-8129005.8745174, 5079109.629390114],
[-8129025.302193181, 5079111.182056908],
[-8129049.202572578, 5079118.395908148],
[-8129086.9775812635, 5079122.980812202],
[-8129109.100649487, 5079121.469314603],
[-8129135.218043816, 5079108.931194168],
[-8129167.613197148, 5079078.690319755],
[-8129182.822070913, 5079065.064996486],
[-8129181.278483726, 5079057.758724603],
[-8129204.097679504, 5079032.774064185],
[-8129214.99022613, 5079016.471062849],
[-8129220.61893867, 5079007.413839884],
[-8129232.576694972, 5078998.7447836185],
[-8129240.374500618, 5078989.068342348],
[-8129249.630138609, 5078971.590758664],
[-8129260.790879304, 5078962.804916313],
[-8129268.571029541, 5078947.989047133],
[-8129335.104177297, 5078911.733269262],
[-8129339.919900289, 5078906.552672157],
[-8129344.3001231905, 5078899.792522252],
[-8129364.160776859, 5078887.032592274],
[-8129369.533906335, 5078870.848897586],
[-8129359.822226193, 5078855.24788273],
[-8129359.369070695, 5078847.450941254],
[-8129351.371170414, 5078825.474152656],
[-8129359.97776194, 5078793.867973295],
[-8129353.0450713355, 5078768.268295512],
[-8129352.23712619, 5078736.063062352],
[-8129351.378737019, 5078718.733351694],
[-8129358.843612096, 5078696.028120396],
[-8129365.7182920715, 5078680.861892963],
[-8129375.054640507, 5078668.748907474],
[-8129391.424567594, 5078655.131145895],
[-8129400.581839735, 5078619.607171483],
[-8129413.436610817, 5078600.052237468],
[-8129417.6827549245, 5078593.744469584],
[-8129420.722809645, 5078586.568763237],
[-8129423.7457189765, 5078572.841273632],
[-8129431.891552103, 5078568.090950122],
[-8129434.148831172, 5078560.512884288],
[-8129434.0376817575, 5078548.240624385],
[-8129436.361177489, 5078540.409680629],
[-8129441.498881791, 5078533.454356207],
[-8129441.9257428385, 5078523.237024807],
[-8129444.634122979, 5078514.7762496015],
[-8129444.179474577, 5078506.4448675355],
[-8129448.912784202, 5078498.182022476],
[-8129455.697037006, 5078489.369699696],
[-8129463.437884235, 5078475.347382799],
[-8129470.083200273, 5078459.812525078],
[-8129481.282094966, 5078449.647187268],
[-8129484.5952934595, 5078433.170536558],
[-8129488.802642334, 5078413.5146552995],
[-8129497.475252737, 5078391.517230599],
[-8129503.541172891, 5078378.1513327835],
[-8129505.375138236, 5078374.524189006],
[-8129509.815793819, 5078371.8601083215],
[-8129510.950345551, 5078364.192826209],
[-8129510.341979874, 5078360.2141348515],
[-8129514.031785691, 5078353.294851441],
[-8129512.639461913, 5078350.0050751595],
[-8129505.620494655, 5078347.203920463],
[-8129500.4142088005, 5078349.6316666445],
[-8129496.628625297, 5078359.32079043],
[-8129487.399085383, 5078372.689642427],
[-8129479.052828579, 5078381.35780116],
[-8129474.766481492, 5078386.1347123],
[-8129472.104555055, 5078400.4661920825],
[-8129459.486175185, 5078409.112715031],
[-8129438.919209571, 5078419.448557096],
[-8129428.2262134785, 5078423.616170423],
[-8129426.606957667, 5078435.491050635],
[-8129405.897669402, 5078443.69478144],
[-8129402.0737551795, 5078458.501482361],
[-8129398.557311587, 5078464.5502914],
[-8129392.296457647, 5078471.279390188],
[-8129378.797932204, 5078481.491723404],
[-8129372.162864631, 5078489.949424977],
[-8129365.297127809, 5078489.447645208],
[-8129357.516490313, 5078486.739612076],
[-8129342.677610256, 5078489.097505832],
[-8129332.589532868, 5078490.052136216],
[-8129328.550294625, 5078490.752414968],
[-8129324.3182733245, 5078490.72048572],
[-8129317.183134156, 5078497.0511355195],
[-8129306.062909283, 5078501.097491179],
[-8129293.810354718, 5078510.524736812],
[-8129283.959465472, 5078512.373927302],
[-8129271.967384654, 5078515.808756697],
[-8129260.0890241675, 5078516.146449078],
[-8129244.7134924, 5078508.827616131],
[-8129237.566439667, 5078498.454940066],
[-8129224.640302143, 5078495.241519761],
[-8129201.251818468, 5078495.989962971],
[-8129196.816183603, 5078495.675541058],
[-8129177.283361053, 5078491.253610857],
[-8129161.939237761, 5078478.734639948],
[-8129156.156829133, 5078469.831250444],
[-8129139.490048739, 5078452.538064459],
[-8129133.078387551, 5078448.668131421],
[-8129126.584414496, 5078440.568384844],
[-8129125.94054075, 5078429.994392168],
[-8129126.03801533, 5078421.847022307],
[-8129118.37543038, 5078408.326338903],
[-8129112.278936996, 5078399.743324223],
[-8129086.266095178, 5078398.821094187],
[-8129076.41946921, 5078389.649266239],
[-8129056.673096683, 5078386.676445394],
[-8129040.4664871935, 5078386.201083619],
[-8129017.284178238, 5078372.779955747],
[-8128988.961244789, 5078365.770179322],
[-8128964.115295498, 5078369.561008501],
[-8128944.125051164, 5078370.321104739],
[-8128932.168088978, 5078380.545002402],
[-8128926.457625414, 5078388.739563757],
[-8128918.400197544, 5078387.863643329],
[-8128918.264981493, 5078359.122353489],
[-8128912.2526963875, 5078344.854261235],
[-8128911.661126167, 5078314.587952909],
[-8128910.3041365165, 5078287.31376946],
[-8128898.018792493, 5078260.860007347],
[-8128854.73758345, 5078254.069814281],
[-8128827.550929156, 5078251.207307605],
[-8128818.636328109, 5078231.099745835],
[-8128813.891693477, 5078198.883730331],
[-8128818.50352663, 5078165.76042535],
[-8128820.975838365, 5078130.461500663],
[-8128792.782108837, 5078095.246373941],
[-8128781.898500011, 5078087.784032109],
[-8128861.462863195, 5078075.30398014],
[-8128888.193006469, 5078068.098531808],
[-8128914.603897219, 5078038.9908408765],
[-8128934.679491097, 5077998.277364565],
[-8128906.465030885, 5077944.309012339],
[-8128872.77767023, 5077836.728644039],
[-8128808.010868913, 5077804.724686067],
[-8128781.2682872275, 5077801.853279687],
[-8128761.478776781, 5077799.5619554045],
[-8128747.636755868, 5077807.95415904],
[-8128714.440072784, 5077764.274875009],
[-8128706.531759569, 5077748.258985694],
[-8128713.127338372, 5077716.327420248],
[-8128719.066549968, 5077679.102776479],
[-8128707.001244446, 5077642.884819724],
[-8128684.898990393, 5077626.969143595],
[-8128665.836992775, 5077597.811661366],
[-8128665.431321376, 5077559.029158057],
[-8128647.554431214, 5077536.736279128],
[-8128630.033073288, 5077545.345502907],
[-8128619.572220937, 5077519.864022579],
[-8128620.461051529, 5077492.619701192],
[-8128635.274894728, 5077453.859973606],
[-8128627.279977507, 5077412.740680985],
[-8128643.68459956, 5077384.407679976],
[-8128625.402037998, 5077367.316776471],
[-8128628.442294433, 5077328.506942293],
[-8128647.729091, 5077269.495061868],
[-8128647.729091, 5077269.495061868],
[-8128647.964835025, 5077269.495061868]
];
const mesa = [
  [-8128651.39183993, 5077259.293675451],
[-8128686.813679824, 5077263.259748952],
[-8128721.936244368, 5077249.456512815],
[-8128896.986299316, 5077151.813083567],
[-8128954.8765613, 5077115.393475834],
[-8128972.911154042, 5077087.865024679],
[-8128986.3330028225, 5077036.361334122],
[-8129150.3163758665, 5077071.145749255],
[-8129199.1763295075, 5077070.856420943],
[-8129260.1504287785, 5077050.626195206],
[-8129340.853930103, 5077013.354856934],
[-8129290.502520113, 5076905.738327877],
[-8129284.425536184, 5076789.430570752],
[-8129274.141167826, 5076650.636249495],
[-8129392.9911368815, 5076645.009113212],
[-8129464.255870864, 5076636.25753463],
[-8129509.044562142, 5076638.477615741],
[-8129552.161611515, 5076549.253592117],
[-8129566.524534753, 5076523.683407548],
[-8129578.634512036, 5076453.1338144345],
[-8129579.451479892, 5076458.377853267],
[-8129578.6439385945, 5076448.357656907],
[-8129574.244880942, 5076360.330027774],
[-8129566.678501779, 5076286.034244046],
[-8129543.721705197, 5076213.56602355],
[-8129528.739771704, 5076166.586343906],
[-8129527.385490382, 5076134.99964532],
[-8129547.790833525, 5076074.677498005],
[-8129547.973080199, 5076075.311806895],
[-8129548.412985965, 5076075.340068182],
[-8129548.412985965, 5076075.340068182],
[-8129548.412985965, 5076075.340068182],
[-8129551.451477929, 5076071.728903715],
[-8129551.451477929, 5076071.728903715],
[-8129548.72092, 5076076.555303528],
[-8129549.1492686905, 5076076.324743884],
[-8129549.474082295, 5076076.099361903],
[-8129560.4506780505, 5076062.439768275],
[-8129569.885395465, 5076054.892428828],
[-8129577.450133345, 5076052.476307712],
[-8129585.17662051, 5076052.5958981505],
[-8129593.8558504125, 5076055.117153719],
[-8129603.315553488, 5076052.96189746],
[-8129607.962229075, 5076048.703295074],
[-8129636.548456289, 5076038.65506986],
[-8129646.033802544, 5076029.192312122],
[-8129647.3882884225, 5076002.803564763],
[-8129623.982640937, 5075966.697765712],
[-8129643.499072903, 5075954.13157038],
[-8129650.5772478925, 5075948.755257351],
[-8129674.946815912, 5075925.09212065],
[-8129691.985722251, 5075908.269294209],
[-8129710.736146428, 5075898.246038424],
[-8129724.668282973, 5075895.339727927],
[-8129734.052371546, 5075892.6988819195],
[-8129747.640626482, 5075885.873684738],
[-8129738.724032791, 5075865.154969766],
[-8129735.549538704, 5075850.322469912],
[-8129747.5242459, 5075792.542546563],
[-8129752.1406756835, 5075772.1582905715],
[-8129755.4821791975, 5075760.771458013],
[-8129776.668047885, 5075725.9075596705],
[-8129783.293850892, 5075723.609057726],
[-8129811.185739714, 5075721.049033829],
[-8129825.941745582, 5075720.479336522],
[-8129832.368978331, 5075719.577151509],
[-8129839.679914488, 5075716.3613514695],
[-8129849.740612818, 5075708.532168419],
[-8129857.047045209, 5075700.298869502],
[-8129866.664891229, 5075683.968711179],
[-8129871.95470654, 5075679.567016117],
[-8129925.6180776, 5075669.705658312],
[-8129938.846529106, 5075666.32421794],
[-8129978.297959516, 5075636.245823515],
[-8129987.156639208, 5075617.103446984],
[-8130000.376394649, 5075595.436504964],
[-8130021.361734626, 5075580.707990075],
[-8130027.538548394, 5075566.775517177],
[-8130053.165846964, 5075551.317007453],
[-8130097.394894559, 5075502.310020695],
[-8130107.320581215, 5075491.404419243],
[-8130146.741575407, 5075460.575172981],
[-8130150.133909703, 5075454.232039268],
[-8130154.525421674, 5075440.223959762],
[-8130196.854810346, 5075443.817978222],
[-8130266.297492659, 5075475.883407744],
[-8130324.393144254, 5075506.584452404],
[-8130416.2792699495, 5075549.536337354],
[-8130502.371665838, 5075603.946332616],
[-8130575.048514458, 5075642.39479341],
[-8130614.820406098, 5075661.322055424],
[-8130639.586243442, 5075662.657816826],
[-8130649.70405228, 5075676.542579546],
[-8130643.686832162, 5075696.156804567],
[-8130680.470401873, 5075742.085529212],
[-8130649.639607639, 5075777.836229031],
[-8130636.9463999495, 5075794.223159092],
[-8130635.912898834, 5075824.482925432],
[-8130623.880845446, 5075854.919203094],
[-8130609.084832947, 5075880.200872922],
[-8130603.172633728, 5075899.691062955],
[-8130613.868057507, 5075910.424859938],
[-8130636.965494667, 5075908.273329966],
[-8130636.965494667, 5075908.273329966],
[-8130639.531346166, 5075905.935747512],
[-8130641.195651408, 5075906.757327459],
[-8130642.869795981, 5075906.477333947],
[-8130704.789349429, 5075914.37391809],
[-8130810.460066334, 5075930.021088268],
[-8130892.444835726, 5075942.159765892],
[-8130945.910765061, 5075952.466595555],
[-8131046.881424677, 5075984.120566924],
[-8131145.220138679, 5076016.309252728],
[-8131174.93791899, 5076039.266282132],
[-8131181.087512099, 5076047.567850989],
[-8131321.967128647, 5075908.789454546],
[-8131395.439724359, 5075832.577531377],
[-8131460.700214679, 5075770.151900376],
[-8131502.855689011, 5075712.114048021],
[-8131525.705940014, 5075664.931885055],
[-8131601.254599333, 5075522.106981639],
[-8131627.847727411, 5075463.700772558],
[-8131666.975834844, 5075377.800525804],
[-8131701.298641, 5075277.729379129],
[-8131714.860811088, 5075223.599899969],
[-8131752.397992488, 5075235.016249964],
[-8131773.7494665, 5075245.666092948],
[-8131773.410683275, 5075245.666092948]
];
const lantern = [
  [-8131778.694076215, 5075253.17788922],
[-8131816.504660137, 5075286.470230562],
[-8131844.093929118, 5075308.336433279],
[-8131859.520168095, 5075328.218161005],
[-8131872.717487335, 5075348.390428546],
[-8131900.652106726, 5075372.579188953],
[-8131939.835282333, 5075425.813126829],
[-8131958.149426015, 5075436.913508689],
[-8131980.11441689, 5075439.17443672],
[-8132013.241626372, 5075459.364312738],
[-8132049.836435892, 5075470.565062898],
[-8132082.0068837805, 5075519.685661304],
[-8132089.888273496, 5075566.19844474],
[-8132080.576146369, 5075600.69344484],
[-8132065.9533756925, 5075661.847674212],
[-8132059.580251033, 5075699.005075544],
[-8132042.302158582, 5075743.686577707],
[-8132036.66378454, 5075760.865404708],
[-8132035.601303432, 5075788.493100041],
[-8132083.501052904, 5075833.930005736],
[-8132128.690858853, 5075784.217762611],
[-8132175.644418685, 5075739.062592496],
[-8132222.96799681, 5075682.602782227],
[-8132293.356048753, 5075605.065628867],
[-8132400.073893553, 5075487.458324884],
[-8132522.671320006, 5075312.118068781],
[-8132563.203466546, 5075260.921152319],
[-8132641.343001222, 5075227.699034798],
[-8132676.408821226, 5075207.137427832],
[-8132674.539791124, 5075161.637741121],
[-8132702.020007782, 5075123.843772565],
[-8132735.506145441, 5075076.624757261],
[-8132737.508119107, 5075140.216469862],
[-8132760.749782143, 5075187.8262416655],
[-8132766.990309432, 5075226.948782316],
[-8132715.408206678, 5075272.073342788],
[-8132733.308666533, 5075340.604217533],
[-8132726.520724569, 5075356.6564945],
[-8132722.5245974455, 5075353.319434001],
[-8132761.914993379, 5075372.763477377],
[-8132770.90041425, 5075431.447288354],
[-8132816.898887325, 5075468.952097101],
[-8132870.897434857, 5075469.819576528],
[-8132924.387668764, 5075471.45293869],
[-8132957.1856279755, 5075507.535393781],
[-8132974.968784686, 5075553.058525882],
[-8132957.490616151, 5075579.114169216],
[-8132916.575279339, 5075574.65173],
[-8132896.579003301, 5075586.038374373],
[-8132895.546735629, 5075626.27847861],
[-8132911.101132594, 5075577.246353152],
[-8132889.259287083, 5075622.550661613],
[-8132858.0879314765, 5075631.366128224],
[-8132806.763895639, 5075711.69784923],
[-8132759.052796654, 5075777.149562765],
[-8132756.487767893, 5075904.637778031],
[-8132720.475702522, 5075931.248295593],
[-8132700.049314955, 5075977.693613031],
[-8132660.025482041, 5075999.888582158],
[-8132628.721182871, 5076046.990370513],
[-8132560.098843321, 5076054.0005421],
[-8132566.237707885, 5076112.676537947],
[-8132472.747101694, 5076149.782775065],
[-8132396.265451461, 5076143.374368486],
[-8132361.4811590025, 5076139.482433759],
[-8132299.795345397, 5076177.964133754],
[-8132263.204584513, 5076214.101294756],
[-8132236.647152591, 5076288.493516982],
[-8132152.759763833, 5076302.123103657],
[-8132120.845488311, 5076306.687139561],
[-8132090.510895136, 5076342.558586144],
[-8132037.943445967, 5076353.601364797],
[-8131991.56178262, 5076341.573879767],
[-8131948.965100736, 5076426.297703818],
[-8131927.631568846, 5076449.922841729],
[-8131881.070040678, 5076459.957468616],
[-8131892.941118903, 5076493.140510486],
[-8131877.167756069, 5076509.059930244],
[-8131888.671284443, 5076496.782361055],
[-8131873.953649909, 5076524.158761354],
[-8131876.072926721, 5076558.631299671],
[-8131850.860570856, 5076599.269975537],
[-8131841.366836359, 5076632.187302989],
[-8131876.049466091, 5076674.013878612],
[-8131911.6861614, 5076683.360774061],
[-8131866.00049671, 5076727.500628156],
[-8131966.451089476, 5076722.936592251],
[-8132017.094767074, 5076734.721808252],
[-8132024.070394069, 5076756.66669322],
[-8132020.543479524, 5076748.343579797],
[-8131994.987034435, 5076782.659815514],
[-8131954.752055861, 5076788.403936045],
[-8131973.457997309, 5076826.6824426595],
[-8131941.520261158, 5076859.083971533],
[-8131955.143066343, 5076928.091569207],
[-8131995.518808691, 5076924.762323838],
[-8132011.417294879, 5076899.63668097],
[-8132049.916186914, 5076895.5962587735],
[-8132109.506184335, 5076857.911702037],
[-8132104.814058553, 5076857.911702037],
[-8132108.716343162, 5076853.042876063]
];
const main = [
  [-8132117.975666212, 5076858.30521265],
[-8132174.025423936, 5076827.084129799],
[-8132234.121841219, 5076793.977261093],
[-8132267.646929951, 5076779.57977591],
[-8132332.412792843, 5076780.254409471],
[-8132394.626173676, 5076786.822225562],
[-8132440.002691026, 5076789.93869889],
[-8132479.027708323, 5076789.105732206],
[-8132616.962217515, 5076784.902810967],
[-8132754.709622582, 5076776.637009805],
[-8132790.271295342, 5076773.092022881],
[-8132817.817474921, 5076775.819778423],
[-8132850.504463002, 5076793.451747928],
[-8132896.960331236, 5076830.321040911],
[-8132927.401057103, 5076854.8910339195],
[-8132962.627298121, 5076878.878791631],
[-8132991.106242435, 5076882.604885638],
[-8132999.860603193, 5076819.105900212],
[-8133009.443420479, 5076749.803072181],
[-8133028.1485011745, 5076648.910297781],
[-8133039.004312639, 5076603.835862281],
[-8133048.359809537, 5076576.440513593],
[-8133078.125309651, 5076517.614555404],
[-8133115.126333558, 5076464.578977726],
[-8133151.479073392, 5076420.128846548],
[-8133219.663403103, 5076349.555533975],
[-8133269.579592814, 5076301.606942728],
[-8133311.687748127, 5076264.071276399],
[-8133385.071821252, 5076203.180570485],
[-8133445.389181649, 5076162.977728009],
[-8133489.698135098, 5076132.046898511],
[-8133597.914091543, 5076065.212369021],
[-8133676.287426245, 5076011.857066758],
[-8133731.959031434, 5075977.084493952],
[-8133745.8457814185, 5075967.228562916],
[-8133746.673305584, 5075967.088456481],
[-8133770.06733934, 5075951.997239313],
[-8133799.842508311, 5075932.255464637],
[-8133829.211654254, 5075912.644801168],
[-8133854.912930053, 5075892.521480863],
[-8133878.185117274, 5075868.359822495],
[-8133900.428832446, 5075841.430306003],
[-8133912.823123466, 5075825.954643233],
[-8133934.816486896, 5075793.243729275],
[-8133941.900012918, 5075775.740257308],
[-8133976.669878815, 5075700.333063492],
[-8133982.915139976, 5075686.526079583],
[-8133981.937039005, 5075673.580858224],
[-8134001.493795786, 5075639.255360359],
[-8134018.995112094, 5075607.01251796],
[-8134033.29899302, 5075591.243089573],
[-8134047.398382429, 5075577.161874327],
[-8134070.8464925, 5075560.233161173],
[-8134094.023200226, 5075547.36006641],
[-8134114.997865104, 5075539.434405347],
[-8134127.9575150255, 5075537.253327135],
[-8134154.863937525, 5075539.764234261],
[-8134229.635659461, 5075550.37736234],
[-8134252.026728819, 5075553.6824133415],
[-8134327.573586549, 5075564.751463268],
[-8134354.887488469, 5075568.793557923],
[-8134432.665308088, 5075580.233743143],
[-8134478.779648897, 5075587.121833068],
[-8134576.882950803, 5075601.899520763],
[-8134640.41179586, 5075610.183968937],
[-8134687.152391942, 5075613.313962679],
[-8134722.2971161, 5075615.455221001],
[-8134790.948376835, 5075614.757245921],
[-8134872.282584422, 5075613.253105868],
[-8134968.259671139, 5075615.033544844],
[-8135030.959919327, 5075613.913094157],
[-8135096.490684875, 5075611.682907477],
[-8135252.833882392, 5075611.036964595],
[-8135310.274392153, 5075610.080295634],
[-8135344.171675535, 5075611.381365422],
[-8135419.226335534, 5075621.19908496],
[-8135461.717792571, 5075627.353910382],
[-8135513.965957173, 5075643.099916133],
[-8135547.608914562, 5075655.276987136],
[-8135569.857891163, 5075606.323497939],
[-8135584.174924751, 5075586.716420446],
[-8135613.159875388, 5075598.258420471],
[-8135620.152834493, 5075623.905663147],
[-8135638.853440326, 5075621.372053386],
[-8135663.202775668, 5075598.68315817],
[-8135708.773147334, 5075498.474694105],
[-8135720.372069609, 5075483.510098436],
[-8135731.886977535, 5075443.283487306],
[-8135758.030266345, 5075412.544331055],
[-8135758.030266345, 5075412.544331055]
];
const matthies = [
  [-8135763.162984013, 5075406.020041352],
[-8135819.031347729, 5075357.81874049],
[-8135841.251481003, 5075338.008713443],
[-8135869.979239049, 5075330.854676129],
[-8135931.1131082885, 5075341.621712171],
[-8135974.263250976, 5075374.333592763],
[-8135988.697636764, 5075410.976296041],
[-8136024.050030818, 5075464.505645649],
[-8136039.474511665, 5075479.305447904],
[-8136062.717743163, 5075517.978026812],
[-8136089.186284311, 5075540.042804931],
[-8136076.562439007, 5075614.48963267],
[-8136112.661904784, 5075635.478007019],
[-8136161.605603689, 5075703.387391395],
[-8136178.143191397, 5075779.716112227],
[-8136186.958037678, 5075895.1131927455],
[-8136209.229175849, 5075940.346137807],
[-8136218.722087228, 5075957.733506786],
[-8136223.042502015, 5076014.815888337],
[-8136254.518523906, 5076070.7139259055],
[-8136248.373933987, 5076098.202699625],
[-8136279.424334662, 5076112.868356921],
[-8136287.782880656, 5076186.2413788205],
[-8136268.292846766, 5076210.959219792],
[-8136227.61986183, 5076231.205469307],
[-8136201.513244247, 5076254.614053707],
[-8136136.733040539, 5076288.138615448],
[-8136082.264521655, 5076308.667585008],
[-8136149.775307913, 5076426.026575201],
[-8136138.826292357, 5076524.126717299],
[-8136157.057783386, 5076585.143920688],
[-8136153.921135613, 5076694.567847523],
[-8136139.515172239, 5076711.762789174],
[-8136153.5919184685, 5076748.249616581],
[-8136131.693551743, 5076796.768564639],
[-8136131.693551743, 5076796.551639386]
];
const nsfwb = [

[-8136112.405970268, 5076812.11401817],
[-8136023.829661216, 5076829.915005215],
[-8135815.275193913, 5076832.046636017],
[-8135763.762048036, 5076754.586698692],
[-8135628.831391963, 5076852.609660967],
[-8135601.91475752, 5076816.377279773],
[-8135583.065094552, 5076813.230586685],
[-8135570.031776822, 5076852.7271944955],
[-8135535.609490988, 5076884.963435741],
[-8135490.036659283, 5076871.352656684],
[-8135403.855315916, 5076879.366306314],
[-8135339.46388357, 5076857.825616108],
[-8135335.90885638, 5076878.618365682],
[-8135307.981845789, 5076893.860327278],
[-8135314.99567387, 5076936.57842224],
[-8135312.622091806, 5076966.122077209],
[-8135330.391881853, 5076996.536548772],
[-8135329.771756809, 5077099.25550973],
[-8135269.047615666, 5077287.934219052],
[-8135277.841630296, 5077524.502498565],
[-8135383.075781041, 5077506.279459306],
[-8135435.775717944, 5077506.4557596035],
[-8135484.963394906, 5077518.818149766],
[-8135514.494177163, 5077510.035189772],
[-8135527.351080009, 5077536.400097054],
[-8135539.587857813, 5077544.819771599],
[-8135529.623089868, 5077538.633234085],
[-8135543.057350514, 5077552.550272276],
[-8135549.509858512, 5077600.942031176],
[-8135574.277438924, 5077652.865138345],
[-8135527.901708281, 5077646.235178885],
[-8135474.298313335, 5077627.712963373],
[-8135407.2232364025, 5077631.201572179],
[-8135376.788996109, 5077643.286155821],
[-8135328.328362301, 5077648.0783183],
[-8135276.152324136, 5077620.97615525],
[-8135239.019664191, 5077661.001663932],
[-8135234.315267307, 5077680.971678809],
[-8135220.464026027, 5077703.28167938],
[-8135258.243540547, 5077766.162116811],
[-8135242.521232326, 5077796.410972947],
[-8135211.632590059, 5077812.288684081],
[-8135210.215925089, 5077863.394398989],
[-8135185.91343846, 5077880.468815134],
[-8135182.32114801, 5077899.510329477],
[-8135106.521553221, 5077950.4611138245],
[-8135079.743912323, 5077969.960994591],
[-8135075.397691111, 5077997.47986742],
[-8135033.485791602, 5078036.1323708035],
[-8135014.074808551, 5078092.495039868],
[-8134977.952524752, 5078114.676822044],
[-8134928.30509991, 5078230.436662163],
[-8134889.74508318, 5078292.670665191],
[-8134888.536908526, 5078294.150519155],
[-8134890.6900665015, 5078305.5283078775],
[-8134889.287533015, 5078370.721539497],
[-8134907.513348881, 5078413.73427351],
[-8134905.149688386, 5078472.470792514],
[-8134967.391561515, 5078508.076983461],
[-8135007.837102087, 5078543.722852578],
[-8135085.279374756, 5078604.620640099],
[-8135125.3825242715, 5078684.893646312],
[-8135034.655092635, 5078755.365187781],
[-8135083.506038846, 5078890.605600321],
[-8134987.468506385, 5079061.349096269],
[-8134965.772385476, 5079219.4397922745],
[-8134880.464030614, 5079243.339525501],
[-8134858.154434628, 5079451.009706313],
[-8134879.423853609, 5079528.5165090365],
[-8134869.970778506, 5079658.985741814],
[-8134962.900249285, 5079827.662087083],
[-8134927.495390899, 5080105.3203029055],
[-8134905.044337523, 5080276.248020487],
[-8134836.266590167, 5080360.122675292],
[-8134802.926831132, 5080426.240182784],
[-8134823.412174958, 5080541.016026203],
[-8134756.301967718, 5080593.205926541],
[-8134713.284823841, 5080607.685398183],
[-8134643.502944478, 5080584.418136965],
[-8134606.594633306, 5080552.409854536],
[-8134547.423842409, 5080542.245971196],
[-8134491.165899327, 5080554.089565701],
[-8134460.952242585, 5080552.634087822],
[-8134411.054425193, 5080535.620896818],
[-8134371.029654079, 5080527.684759959],
[-8134331.077947291, 5080504.976043473],
[-8134315.97111892, 5080504.197342423],
[-8134289.196580816, 5080523.844255304],
[-8134182.298329722, 5080524.867574485],
[-8134164.952548071, 5080483.564008598],
[-8134144.10838233, 5080479.444250202],
[-8134093.360096122, 5080488.651831895],
[-8134044.887770859, 5080499.833138145],
[-8133986.571757759, 5080502.881446068],
[-8133947.50109486, 5080478.99311316],
[-8133877.168885749, 5080447.968042893],
[-8133862.22801538, 5080413.773555505],
[-8133838.383719302, 5080385.809324747],
[-8133819.181630025, 5080374.669136388],
[-8133772.424996861, 5080346.657803975],
[-8133738.744159925, 5080310.835499431],
[-8133704.621893286, 5080263.645663332],
[-8133654.583862901, 5080209.901252241],
[-8133609.208879768, 5080152.614974501],
[-8133582.311595328, 5080112.064713947],
[-8133565.958744696, 5080068.902488238],
[-8133523.160015141, 5080034.131948293],
[-8133482.967466598, 5079992.532415478],
[-8133448.553407445, 5079969.251531407],
[-8133428.671613143, 5079963.661228124],
[-8133396.539521096, 5079917.67269661],
[-8133379.623037058, 5079891.667692392],
[-8133360.516862177, 5079872.5863897],
[-8133307.360745298, 5079853.235915027],
[-8133283.762960844, 5079842.105155125],
[-8133270.432781397, 5079820.803183585],
[-8133270.916608304, 5079792.7370198425],
[-8133294.210130479, 5079766.345703985],
[-8133334.3453180995, 5079768.20996919],
[-8133346.00953686, 5079768.135199196],
[-8133353.753261308, 5079755.850489037],
[-8133364.68425785, 5079787.493150868],
[-8133392.409534726, 5079842.112632125],
[-8133412.291329029, 5079873.962157608],
[-8133425.606544756, 5079880.220406179],
[-8133439.667452468, 5079886.022557781],
[-8133488.733486229, 5079925.879949715],
[-8133532.769216535, 5079957.51014988],
[-8133613.845138633, 5080044.455192249],
[-8133669.292813389, 5080099.89021391],
[-8133679.704319898, 5080090.617686934],
[-8133655.51887843, 5080000.728419743],
[-8133637.84336073, 5079944.019290301],
[-8133637.734072695, 5079905.276364485],
[-8133651.697440242, 5079858.069117809],
[-8133649.80675729, 5079815.12133582],
[-8133641.989020072, 5079797.857761845],
[-8133622.124098799, 5079775.886933304],
[-8133612.404749826, 5079778.202334622],
[-8133614.630582742, 5079776.174538185],
[-8133598.729174095, 5079784.187428594],
[-8133590.59085865, 5079756.67565539],
[-8133590.59085865, 5079756.67565539]
];



const spur_path_ll = [
  [-8133420, 5078950],
  [-8133540, 5078950],
  [-8133500, 5079100],
  [-8133400, 5079200],
  [-8133200, 5079300],  
  [-8132750, 5079450], 
];

const spur_path_39_1 = [
  [-8134715.161784699, 5077693.6814516],
  [-8134736.922796478, 5077687.249025673],
  [-8134768.684811045, 5077655.582520871],
  [-8134790.5171821015, 5077609.132846505],
  [-8134808.70309406, 5077576.945757345],
  [-8134823.5707995985, 5077559.880574147],
  [-8134847.593900484, 5077519.55311448],
  [-8134880.68319762, 5077469.9834996015],
  [-8134914.230685196, 5077403.618860585],
  [-8134920.141500127, 5077387.03613242],
  [-8134937.156962725, 5077365.880040729],
  [-8134941.153538113, 5077333.026560472],
  [-8135033.356076068, 5077240.949051055],
  [-8135091.8432982145, 5077077.665574302],
  [-8135160.999489851, 5077005.1534214355],
  [-8135164.865704839, 5076974.113516377],
  [-8135180.488369491, 5076925.863502692],
  [-8135207.551874418, 5076906.832962265],
  [-8135305.268408098, 5076898.883281719],
  [-8135305.268408098, 5076898.883281719]
];

const spur_path_39_2 = [
[-8134654.90345661, 5078112.506462519],
[-8134932.0542626735, 5078202.927252655],
[-8134932.0542626735, 5078202.927252655]
];

const spur_path_lorraine = [
  [-8130184.392726564, 5079196.392981552],
[-8130164.199380441, 5079177.743496176],
[-8130139.893990945, 5079173.358059165],
[-8130129.644387288, 5079217.053948877],
[-8130116.646913699, 5079270.060358682],
[-8130080.32028204, 5079378.560660136],
[-8130039.9717010455, 5079640.140217143],
[-8129992.397634608, 5079703.431379878],
[-8129977.3240333265, 5079788.806521748],
[-8129960.02009928, 5079841.760104749],
[-8129937.064240934, 5079921.201857327],
[-8129925.991415143, 5079956.311360452],
[-8129925.991415143, 5079956.311360452]
];

const spur_path_39_3 = [
[-8133949.724596502, 5077948.929499004],
[-8134352.150990586, 5077883.679834982],
[-8134351.493498793, 5077880.798815334]
];
 
const toby = [
  [-8134308.380135, 5075532.605345588],
[-8134337.796646845, 5075366.305932443],
[-8134308.331391816, 5075331.450370364],
[-8134236.376702859, 5075293.316748857],
[-8134213.540520921, 5075284.524925275],
[-8134199.814440167, 5075222.597365707],
[-8134214.656739846, 5075083.671940667],
[-8134207.4963660445, 5074821.188516291],
[-8134212.677766557, 5074697.39671777],
[-8134208.110530171, 5074478.838315586],
[-8134226.423344587, 5074396.901442294],
[-8134229.869487731, 5074344.832415097],
[-8134248.3382803425, 5074176.764895605],
[-8134288.999844851, 5074002.837783994],
[-8134296.776566239, 5073954.32180617],
[-8134350.647533747, 5073925.325836207],
[-8134384.743391304, 5073756.6007565],
[-8134420.974200342, 5073655.355967042],
[-8134483.505561367, 5073586.238412521],
[-8134548.787308365, 5073548.372685914],
[-8134549.640314094, 5073626.388552013],
[-8134517.942621215, 5073724.277348978],
[-8134484.553539835, 5073858.356311712],
[-8134446.938424357, 5074067.163339483],
[-8134421.294634992, 5074202.30414019],
[-8134408.119352223, 5074438.7676634155],
[-8134386.691848318, 5074514.348121321],
[-8134356.7537844, 5074702.478533521],
[-8134370.260520825, 5074788.97936177],
[-8134355.871532761, 5074924.022746148],
[-8134361.1357966885, 5074969.5356544815],
[-8134354.974658168, 5075261.360876783],
[-8134345.757321981, 5075363.828241154],
[-8134345.757321981, 5075364.768308716]
];

const coords =   {
  origin: [-73.064, 41.45],
  start: [-73.0533519, 41.467716],
  end: [-73.064, 41.45],
};


const nsfebpath = new LineString(nsfeb);
const bethanypath = new LineString(bethany);
const mesapath = new LineString(mesa);
const lanternpath = new LineString(lantern);
const mainpath = new LineString(main);
const matthiespath = new LineString(matthies);
const nsfwbpath = new LineString(nsfwb);

const ll_spur_path = new LineString(spur_path_ll);
const spur_path_39_ac_1 = new LineString(spur_path_39_1);
const spur_path_39_ac_2 = new LineString(spur_path_39_2);
const spur_path_39_ac_3 = new LineString(spur_path_39_3);
const spur_path_lorraine_prop = new LineString(spur_path_lorraine);
const toby_path = new LineString(toby);

const walkaboutlength = nsfebpath.getLength() +
bethanypath.getLength() +
mesapath.getLength() +
lanternpath.getLength() +
mainpath.getLength() +
matthiespath.getLength() +
nsfwbpath.getLength() + 
toby_path.getLength() ;


const layersButton = <button>&#9776;</button>;

export default function Map()  {
  const [path, setPath] = React.useState([]);
  const [distance, setDistance] = React.useState(0);
  return (
    <>
    <RMap  
      className="walkabout-map"
      initial={{ center: fromLonLat(coords.origin), zoom: 14 }}
      onClick={useCallback((e) => {
        const coords = e.map.getCoordinateFromPixel(e.pixel);
        console.log("Adding to path: ", coords);
        setPath([...path, coords]);
        setDistance(path.length > 1 ? new LineString([...path, coords]).getLength() : 0);
    }, [path])}
    >
      <ROSM />
      <GeolocComp />
      <RLayerVector>
          <RFeature geometry={nsfebpath} />
          <RStyle.RStyle>
                        <RStyle.RStroke width={5} color="#32bf30">
                            
                        </RStyle.RStroke>
                    </RStyle.RStyle>
      </RLayerVector>
      <RLayerVector>
          <RFeature geometry={bethanypath} />
          <RStyle.RStyle>
                        <RStyle.RStroke width={5} color="#39ccca">
                  
                        </RStyle.RStroke>
                    </RStyle.RStyle>
      </RLayerVector>
      <RLayerVector>
          <RFeature geometry={mesapath} />
          <RStyle.RStyle>
                        <RStyle.RStroke width={5} color="#2e7aa3">
                  
                        </RStyle.RStroke>
                    </RStyle.RStyle>
      </RLayerVector>
      <RLayerVector>
          <RFeature geometry={lanternpath} />
          <RStyle.RStyle>
                        <RStyle.RStroke width={5} color="#2e41a3">
                  
                        </RStyle.RStroke>
                    </RStyle.RStyle>
      </RLayerVector>
      <RLayerVector>
          <RFeature geometry={mainpath} />
          <RStyle.RStyle>
                        <RStyle.RStroke width={5} color="#6640e3">
                  
                        </RStyle.RStroke>
                    </RStyle.RStyle>
      </RLayerVector>

      <RLayerVector>
          <RFeature geometry={matthiespath} />
          <RStyle.RStyle>
                        <RStyle.RStroke width={5} color="#ac40e3">
                  
                        </RStyle.RStroke>
                    </RStyle.RStyle>
      </RLayerVector>
      <RLayerVector>
          <RFeature geometry={nsfwbpath} />
          <RStyle.RStyle>
                        <RStyle.RStroke width={5} color="#e340db">
                  
                        </RStyle.RStroke>
                    </RStyle.RStyle>
      </RLayerVector>
      <RControl.RLayers element={layersButton}>
      <RLayerVector>
          <RFeature geometry={toby_path} />
          <RStyle.RStyle>
                        <RStyle.RStroke width={5} color="#5010e3">
                  
                        </RStyle.RStroke>
                    </RStyle.RStyle>
      </RLayerVector>
      <RLayerVector
          properties={{label: "Laurel Ledge Spur Path"}}>
          <RFeature geometry={ll_spur_path} />
          <RStyle.RStyle>
                        <RStyle.RStroke width={5} color="#db7d02">
                            
                        </RStyle.RStroke>
                    </RStyle.RStyle>
      </RLayerVector>
      <RLayerVector
          properties={{label: "Pent Rd Connector #1"}}>
          <RFeature geometry={spur_path_39_ac_1} />
          <RStyle.RStyle>
                        <RStyle.RStroke width={5} color="#e36e40">
                            
                        </RStyle.RStroke>
                    </RStyle.RStyle>
      </RLayerVector>
      <RLayerVector
          properties={{label: "Pent Rd Connector #2"}}>
          <RFeature geometry={spur_path_39_ac_2} />
          <RStyle.RStyle>
                        <RStyle.RStroke width={5} color="#e36e40">
                            
                        </RStyle.RStroke>
                    </RStyle.RStyle>
      </RLayerVector>
      <RLayerVector
          properties={{label: "Lorraine Rd Spur Path"}}>
          <RFeature geometry={spur_path_lorraine_prop} />
          <RStyle.RStyle>
                        <RStyle.RStroke width={5} color="#e36e40">
                            
                        </RStyle.RStroke>
                    </RStyle.RStyle>
      </RLayerVector>

    
      <RLayerVector
        properties={{ label: "Proposed Return to Pent Rd Path"}}
        >
          <RFeature geometry={spur_path_39_ac_3} />
          <RStyle.RStyle>
                        <RStyle.RStroke width={5} color="#e36e40">
                            
                        </RStyle.RStroke>
                    </RStyle.RStyle>
      </RLayerVector>
      {/* <RLayerVector
          properties={{ label: "Draw (hold shift key down)" }}
                    onClick={React.useCallback((e) => {
                          const coords2 = e.map.getCoordinateFromPixel(e.pixel);
                        console.log("Current Coordinates: ", coords2);

                    }, [])}
                >
               
                    <RStyle.RStyle>
                        <RStyle.RStroke color='#0000ff' width={3} />
                        <RStyle.RFill color='rgba(0, 0, 0, 0.75)' />
                    </RStyle.RStyle>

                    <RInteraction.RDraw
                        type={'LineString'}
                        condition={shiftKeyOnly}
                        freehandCondition={altShiftKeysOnly}
                    />
                </RLayerVector>  */}
           </RControl.RLayers>
               
    </RMap>
    <Typography color="#32bf30"> Naugatuck State Forest East Block path:  {Math.round(nsfebpath.getLength()/16.09, 2)/100} miles</Typography>
   <Typography color="#39ccca">Bethany path: {Math.round(bethanypath.getLength()/16.09, 2)/100} miles</Typography>   
   <Typography color="#2e7aa3">Mesa path: {Math.round(mesapath.getLength()/16.09, 2)/100} miles</Typography> 
   <Typography color="#2e41a3">Lantern path: {Math.round(lanternpath.getLength()/16.09, 2)/100} miles</Typography> 
   <Typography color="#6640e3">Main path: {Math.round(mainpath.getLength()/16.09, 2)/100} miles</Typography> 
   <Typography color="#5010e3">Toby's Pond loop path: {Math.round(toby_path.getLength()/16.09, 2)/100} miles</Typography> 
   
   <Typography color="#ac40e3">Matthies path: {Math.round(matthiespath.getLength()/16.09, 2)/100} miles</Typography>   
   <Typography color="#e340db">Naugatuck State Forest West Block path: {Math.round(nsfwbpath.getLength()/16.09, 2)/100} miles</Typography>                  
    <Typography>Total Walkabout Length: {Math.round(walkaboutlength/16.09, 2)/100} miles</Typography>
    {path.length > 1 && (
     <Typography>Coordinates:</Typography>  )}
     
   {/* {path.map((c, i) => (
        <p key={i}>{c[0]}, {c[1]}</p>
    ))}    */}
    {path.length > 1 && (
   <Typography>Total Drawn Length: {distance/1609} </Typography>
  )}
 
 </>
  );
}