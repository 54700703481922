import { Typography } from "@mui/material";
import React from "react";
import Grid from '@mui/material/Grid';
import Layout from "./layout";
import Box from '@mui/material/Box';
import { Link } from "react-router-dom";


const imageContent = (data, index) => {
    const content =  
        <div> 
        <Box sx={{ m: 2}}> 
          {data.caption ? <Typography variant="h6">{data.caption}</Typography> : null}
        </Box>
        <Box sx={{ m: 2}}>  <img src={data.src} alt={data.caption} key={index} title={data.caption}  height="600"/>
       </Box>
        </div> 
   
    return content;

}

const textContent = (data, index) => { 
    let content  = []
     if (data.txt)   {
        let txtContent = [];
    if (data.txt instanceof Array) { 
        txtContent = data.txt.map((d, i) => (
            d.t === "lnk" ?  <Link to={d.href} key={d.href} target="_blank" >{d.txt}</Link> : d.txt
            ));
             
    } else {
        txtContent.push(data.txt);
    }  
     
    content.push(<Box sx={{ m: 2 }} ><Typography variant="body1"> {txtContent} </Typography></Box>)
    
 }
    return content;
}
export default function RouteSection({data }) {
    //console.log('RouteSection: title: ', data.title);
    return (
    <Layout>
    <Grid container>    
        <Grid item xs={12}>
            <Typography sx={{ m: 2}} variant="h4">{data.title}</Typography>
        </Grid> 
        <Grid item xs={12}>
            
            <Box sx={{ m: 2 }}> <Typography>Next Section: &nbsp; <Link to={data.next_url} key={data.next_url} >{data.next_title}</Link>
            </Typography>
            </Box>
            <Box sx={{ m: 2 }}> <Typography>Prev Section: &nbsp; <Link to={data.prev_url} key={data.prev_url} >{data.prev_title}</Link>
            </Typography>
            </Box>
      </Grid>
      <Grid item xs={12}>
            {data.content ? 
                data.content.map((d, i) => (
                    d.type.toLowerCase() === 'img' ? imageContent(d, i) : textContent(d, i)

            ))
            : null}
         </Grid> 
        <Grid item xs={12}>
            {data.images ? data.images.map((data, index) => (   
              <div> 
              <Box sx={{ m: 2}}> 
                {data.caption ? <Typography variant="h6">{data.caption}</Typography> : null}
              </Box>
              <Box sx={{ m: 2}}>  <img src={data.src} alt={data.caption} key={index} title={data.caption} />
             </Box>

              </div> 
            )) : null}
            
         </Grid>

        </Grid>
       
    </Layout>
    );
}