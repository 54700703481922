import React from 'react';
import Layout from './layout';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";

import ImageMapper from 'react-img-mapper';

const MAP = {
    name: "my-map", 
    areas: [
        { name: "nsfeb", shape: "rect", coords: [600,0,1000,300], preFillColor: "transparent", fillColor: "rgba(0,0,0,0.2)", strokeColor: "transparent", href: "/nsfeb" },
        { name: "bethany", shape: "rect", coords: [1000,0,1100,600], preFillColor: "transparent", fillColor: "rgba(0,0,0,0.2)", strokeColor: "transparent", href: "/bethany" },
        { name: "mesa", shape: "rect", coords: [650,600,1100,900], preFillColor: "transparent", fillColor: "rgba(0,0,0,0.2)",  strokeColor: "transparent", href: "/mesa" },
        { name: "lantern", shape: "rect", coords: [500,600,650,900], preFillColor: "transparent", fillColor: "rgba(0,0,0,0.2)", strokeColor: "transparent", href: "/lantern_ridge" },
        { name: "main", shape: "rect", coords: [200,650,500,900], preFillColor: "transparent", fillColor: "rgba(0,0,0,0.2)", strokeColor: "transparent", href: "/main_st" },
        { name: "matthies", shape: "rect", coords: [0,700,200,900], preFillColor: "transparent", fillColor: "rgba(0,0,0,0.2)", strokeColor: "transparent", href: "/matthies" },
        { name: "nsfwb", shape: "rect", coords: [50,100,450,650], preFillColor: "transparent", fillColor: "rgba(0,0,0,0.2)", strokeColor: "transparent", href: "/nsfwb" },

    ]
}

export default function Home() {
    return (
        <Layout   >
                  
      <Box sx={{ m: 2 }}> 
      <Typography  variant="h3" >Beacon Falls Walkabout</Typography>
      </Box>
      <Box sx={{ m: 2  }}>
        
        <Typography>Beacon Falls Walkabout is a project to encourage the exploration of the beautiful natural areas of Beacon Falls, CT.</Typography>
        <Typography>The town is surrounded on all sides by State Forest, Town land, and other conservation land.   </Typography>
        <Typography>But, the town has not done a great job helping residents with information on how to access these lands or where to begin.</Typography>
        <Typography>By laying out a route around the town, we challenge residents to get to know the WHOLE town!</Typography>
        <Typography>For the most fit, this route could be done in a day. </Typography>
        <Typography>For the rest of us, plan to enjoy it in sections and keep going section by section until you have made the complete circuit.</Typography>
        <Typography>Most of the proposed route is over existing trails on State Forest or Town lands.</Typography> 
        <Typography>We have tried to minimize road walking, but roads are the only way to get across the river at the moment.</Typography>  
        <Typography>Planning this route has made me realize how much potential the town has 
            in the beauty of its natural resources </Typography>
        <Typography>
            and how much more could be done to improve the accessibility.  </Typography>
        <Typography>
            From the hills to the river, Beacon Falls has a stunning number of natural places. 
            </Typography>
        <Typography> If you'd like to get involved in helping us to make this walkabout a reality, 
            check out the <Link to="/planning">planning</Link> page.</Typography>
      </Box>
      <Box sx={{ display: 'inline-flex', m: 2, border: 1 }}>
      <ImageMapper src="img/home/route_overview_2.png" alt="Beacon Falls" map={MAP} />

      </Box>
      <Box sx={{ m: 4 }}> <Typography>Get Started: &nbsp; <Link to="/nsfeb" key="nsfeb" >Naugatuck State Forest East Block</Link>
              </Typography>
              </Box>
        </Layout>
  
    );
}