import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './pages/home';
import Explore from './pages/explore';
import RouteSection from './pages/route_section';
import CompleteLoop from './pages/complete_loop';
import Gallery from './pages/gallery';
import Planning from './pages/planning';
import reportWebVitals from './reportWebVitals';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import data from './data/route_descriptions.json';
import theme from './theme';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
 

const router = createBrowserRouter([{
  path: "/",
  element: <Home />,
}, 
{
  path: "/nsfeb",
  element: <RouteSection data={data.route_sections.nsfeb}/>,
},
{
  path: "/bethany",
  element: <RouteSection  data={data.route_sections.bethany}/>,
},
{
  path: "/lantern_ridge",
  element: <RouteSection data={data.route_sections.lantern_ridge} />,
},
{
  path: "/matthies",
  element: <RouteSection data={data.route_sections.matthies} />,
},
{
  path: "/nsfwb",
  element: <RouteSection data={data.route_sections.nsfwb} />,
},
{
  path: "/mesa",
  element: <RouteSection data={data.route_sections.mesa} />,
},
{
  path: "/kaleas",
  element: <RouteSection data={data.route_sections.kaleas} />,
},
{
  path: "/main_st",
  element: <RouteSection data={data.route_sections.main_st} />,
},
{
  path: "/map",
  element: <Explore  />,
},
{
  path: "/backtostart",
  element: <CompleteLoop  />,
},
{
  path: "/planning",
  element: <Planning  />,
},
{
  path: "/gallery",
  element: <Gallery  />,
},


]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <ThemeProvider theme={theme}></ThemeProvider>
     <CssBaseline />
     <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
