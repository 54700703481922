import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ListItem } from '@mui/material';
import { Link } from "react-router-dom";
import Divider from '@mui/material/Divider';

export default function NavMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Menu
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}><Link to="/">Home</Link></MenuItem>
        <MenuItem onClick={handleClose}><Link to="/map">Explore</Link></MenuItem>
        <Divider/> 
        <ListItem sx={{fontWeight: 'bold', fontSize: '16'}}>Trail Sections</ListItem>
        <MenuItem onClick={handleClose} ><Link to="/nsfeb">Naugatuck State Forest East Block</Link></MenuItem>
        <MenuItem onClick={handleClose}><Link to="/bethany">Bethany Border</Link></MenuItem>
        <MenuItem onClick={handleClose}><Link to="/lantern_ridge">Lantern Ridge</Link></MenuItem>
        <MenuItem onClick={handleClose}><Link to="/matthies">Matthies Park</Link></MenuItem>
        <MenuItem onClick={handleClose}><Link to="/nsfwb">NSF West Block</Link></MenuItem>
        <Divider/>
        <ListItem sx={{fontWeight: 'bold', fontSize: '16'}}>Road Sections</ListItem>
        <MenuItem onClick={handleClose}><Link to="/mesa">Mesa Drive to Oakwood Drive</Link></MenuItem>
        <MenuItem onClick={handleClose}><Link to="/kaleas">Kaleas Way to Lantern Ridge</Link></MenuItem>
        <MenuItem onClick={handleClose}><Link to="/main_st">Starwood Lane to Matthies Park</Link></MenuItem>
        <Divider/>
        <MenuItem onClick={handleClose}><Link to="/backtostart">Completing the Loop</Link></MenuItem>
        <MenuItem onClick={handleClose}><Link to="/planning">Planning the Trail</Link></MenuItem>
        <MenuItem onClick={handleClose}><Link to="/gallery">Gallery</Link></MenuItem>
         
        
      </Menu>
    </div>
  );
}