import React from 'react';
import NavMenu from '../components/NavMenu';
import { Box } from '@mui/material';
export default function Layout({children}) {
    return (
        <Box sx={{ m: 2}}>
            <NavMenu />
            {children}
        </Box>
    );
}